const links = [
    {id: 1,
    text: 'Home',
path: '/'},
{id: 2,
    text: 'About',
path: '/About'},
{id: 3,
    text: 'Gallery',
path: '/Gallery'},
{id: 4,
    text: 'Available Kittens',
path: '/Available'},
{id: 5,
    text: 'Our Clients',
path: '/OurClients'},
{id: 6,
    text: 'Contact',
path: '/Contact'},
{id: 7,
text: 'Cat Game!',
path: "https://flip-card-memory-game.netlify.app"},
];
export default links;