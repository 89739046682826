
const availableKittens = [
    {
        id: 1,
        color: 'Golden',
        sex: 'Male',
        img:"https://i.ibb.co/PMTxHM5/IMG-4217-copy.jpg",
    },    
    {
        id: 2,
        color: 'Golden2',
        sex: 'Female',
        img:"https://i.ibb.co/3SqYBh5/IMG-4207.webp",
    },
    {
        id: 3,
        color: 'Golden3',
        sex: 'Female',
        img:"https://i.ibb.co/fSN1m9d/IMG-4219.webp",
    },
    {
        id: 4,
        color: 'Golden4',
        sex: 'Female',
        img:"https://i.ibb.co/kXsnnHv/IMG-4215.webp",
    },
    {
        id: 5,
        color: 'Golden5',
        sex: 'Female',
        img:"https://i.ibb.co/NWyJ4Dw/IMG-4216.webp",
    },
    {
        id: 6,
        color: 'Golden6',
        sex: 'Female',
        img:"https://i.ibb.co/W2VnL7W/IMG-4198.webp",
    },
    {
        id: 7,
        color: 'Golden7',
        sex: 'Female',
        img:"https://i.ibb.co/S7QrfNR/IMG-4206.webp",
    },
    {
        id: 8,
        color: 'Golden8',
        sex: 'Female',
        img:"https://i.ibb.co/mG7jFHX/IMG-4223.webp",
    },
    {
        id: 9,
        color: 'Golden9',
        sex: 'Female',
        img:"https://i.ibb.co/1f3P0YP/IMG-4218.webp",
    },
    {
        id: 10,
        color: 'Golden10',
        sex: 'Female',
        img:"https://i.ibb.co/8x1Qw47/IMG-4208.webp",
    },
    {
        id: 12,
        color: 'Golden12',
        sex: 'Female',
        img:"https://i.ibb.co/LtY6Q47/IMG-4211.webp",
    },
    {
        id: 13,
        color: 'Golden13',
        sex: 'Female',
        img:"https://i.ibb.co/YD1nYnC/IMG-4167.webp",
    },

];

export default availableKittens;





