
const cats = [
{  id: "1",
    name: 'Lilac boy',
    img: "https://i.ibb.co/xjzSZ8p/Facetune-20-04-2023-16-00-06-copy.webp",
    avail: 'Not available'
},
{  id: "2",
    name: 'Many',
    img: "https://i.ibb.co/WcX3sZ7/Many.webp",
    avail: 'Not available'
},
{  id: "3",
    name: 'Fluff',
    img: "https://i.ibb.co/5LRpXD3/cat11.webp",
    avail: 'Not available'
},
{  id: "4",
    name: 'lilac boy',
    img: "https://i.ibb.co/mzzsK8c/IMG-3636-copy.webp",
    avail: 'Not available'
},
{  id: "5",
    name: 'Lilac boy and girl',
    img: "https://i.ibb.co/KynrN0j/cat3.webp",
    avail: 'available'
},
{  id: "6",
    name: 'Roxy',
    img: "https://i.ibb.co/VTXmBh9/cat6.webp",
    avail: 'Not available'
},
{  id: "7",
    name: 'Many',
    img: "https://i.ibb.co/fYFKd7f/IMG-8567.webp",
    avail: 'Not available'
},
{  id: "8",
name: 'Babies',
img: "https://i.ibb.co/h1KyW9k/IMG-2176.webp",
},
{  id: "9",
    name: 'The cat band',
    img: "https://i.ibb.co/FhPPvT8/cat9.webp",
    avail: 'available'
},
{  id: "10",
    name: 'Cream tabby boy',
    img: "https://i.ibb.co/VBmDnhv/c7.webp",
    avail: 'Not available'
},
{  id: "11",
    name: 'Chocolate and Lilac',
    img: "https://i.ibb.co/ch5Dgz5/cat2.webp",
    avail: 'available'
},
{  id: "12",
    name: 'Babies',
    img: "https://i.ibb.co/8Nnhfzf/cat8.webp",
},
{  id: "13",
    name: 'Babies',
    img: "https://i.ibb.co/TLW7SbT/lilac-Male.webp",
},
{  id: "14",
    name: 'Chocolate',
    img: "https://i.ibb.co/YtG0HQg/IMG-2182.webp",
},
{  id: "15",
    name: 'Daddy',
    img: "https://i.ibb.co/52cQp3X/Many2.webp",
},
{  id: "16",
    name: 'Babies',
    img: "https://i.ibb.co/SnWLS7m/c2.webp",
},
{  id: "17",
    name: 'Babies',
    img: "https://i.ibb.co/KxqD861/lilac-Female.webp",
},
{  id: "18",
    name: 'Babies',
    img: "https://i.ibb.co/1f3P0YP/IMG-4218.webp",
},
{  id: "19",
    name: 'Babies',
    img: "https://i.ibb.co/9tW8tkQ/cat10.webp",
},
{  id: "20",
name: 'Many',
    img: "https://i.ibb.co/1vkp2kx/Many.webp",
    avail: 'Not available'
   
},
{  id: "21",
    name: 'Roxy',
    img: "https://i.ibb.co/JzXy7ph/cat1.webp",
},
{  id: "22",
    name: 'Chocolate girl',
    img: "https://i.ibb.co/8jqWcF8/Facetune-04-05-2023-00-40-33.webp",
},
{  id: "23",
    name: 'Babies',
    img: "https://i.ibb.co/ggSByg5/IMG-2184.webp",
},
{  id: "24",
    name: 'Chocolate',
    img: "https://i.ibb.co/zXLBysh/IMG-2196.webp",
},
{  id: "25",
    name: 'Lilac',
    img: "https://i.ibb.co/VHJ8ZpD/IMG-3171.webp",
},
{  id: "26",
    name: 'Babies',
    img: "https://i.ibb.co/N9GsnMs/IMG-6411.webp",
},
{  id: "27",
    name: 'Babies',
    img: "https://i.ibb.co/qdZS27k/cat7.webp",
},
{  id: "28",
    name: 'Babies',
    img: "https://i.ibb.co/V91KxsQ/IMG-2183.webp",
},
{  id: "29",
    name: 'Babies',
    img: "https://i.ibb.co/44XtF5B/EFFD9-C44-CAA5-4-A17-938-E-92-C159390420.webp",
},
{  id: "30",
    name: 'Babies',
    img: "https://i.ibb.co/199dc3h/IMG-2179.webp",
},

];

export default cats;